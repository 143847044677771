<template>
    <div class="hardClassUpload">
        <div class="search_container searchArea">
            <el-row>
                <el-col :span="12">
                    <el-upload class="upload-demo" drag action
                            :multiple="false"
                            :show-file-list="false"
                            accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            :http-request="httpRequest">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传excel文件，且不超过50M</div>
                    </el-upload>
                </el-col>
                <el-col :span="8" :offset="4">
                    <el-row>
                        <el-col :span="24">
                            <el-link :href="downloadUrl" download="行政班示例.xlsx" type="primary">下载模板示例</el-link>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-checkbox v-model="overWrite">覆盖原有信息</el-checkbox>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top: 20px">
                        <el-col :span="24">
                            <el-button type="primary" :loading="loading" :disabled="!isCanUpload" @click="">上  传</el-button>
                        </el-col>
                    </el-row>

                </el-col>
            </el-row>


        </div>
        <div class="table_container">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>

                <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                <el-table-column prop="schoolName" label="所属单位" align='center' ></el-table-column>
                <el-table-column prop="professionName" label="专业" align='center' ></el-table-column>
                <el-table-column prop="status" label="状态" align='center' ></el-table-column>
            </el-table>

        </div>

    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import * as simpleApi from "request/simple";
    import XLSX from "xlsx";  //引入xlsx
    export default {
        name: 'hardClassUpload',
        data(){
            return {
                tableData: [], tableHeight:0,
                loading:false, isShow:false,
                downloadUrl: `${process.env.BASE_URL}/hardclass_demo.xlsx`,
                selHardClass: null, selHardClassDtl: null,
                overWrite: false, isCanUpload: false,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, schoolId: null, professionId: null},
            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {

	   },
        methods: {

            httpRequest(e) {
                let file = e.file; // 文件信息
                if (!file) {
                    // 没有文件
                    return false;
                } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
                    // 格式根据自己需求定义
                    this.$message.error("上传格式不正确，请上传xls或者xlsx格式");
                    return false;
                }

                const fileReader = new FileReader();
                let this_ = this;
                fileReader.onload = ev => {
                    try {
                        const data = ev.target.result;
                        const workbook = XLSX.read(data, {
                            type: "binary" // 以字符编码的方式解析
                        });
                        console.log(workbook)
                        const exlname = workbook.SheetNames[0]; // 取第一张表
                        let exlData = XLSX.utils.sheet_to_json(workbook.Sheets[exlname]); // 生成json表格内容
                        console.log(exlData);
                        if(!exlData || exlData.length == 0){
                            this.$message.error("数据格式错误");
                            return false;
                        }
                        let first = exlData[0];
                        console.log(first)
                        if('行政班名称' in first && '所属单位' in first && '专业' in first && '年级' in first){
                            console.log('true');
                            let tableData = [];
                            for(let i=0; i<exlData.length;i++){
                                let d = exlData[i];
                                let p = { id: null, licenceId: this.userInfo.licenceId,  name: d['行政班名称'], year: d['年级'],
                                    schoolName: d['所属单位'], professionName: d['专业'], status: '检测中' }
                                tableData.push(p);
                                this.tableData = tableData;
                            }
                        }else{
                            this.$message.error("数据格式错误");
                            return false;
                        }



                    } catch (e) {
                        console.log("出错了：：");
                        return false;
                    }
                };
                fileReader.readAsBinaryString(file);
            },

        },


    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .search_container{

    }
    .btnRight{
        float: right;
        margin-right: 0px !important;
    }
    .searchArea{
        background:rgba(255,255,255,1);
        border-radius:2px;
        padding: 18px 18px 0;
    }
    .table_container{
        padding: 10px;
        background: #fff;
        border-radius: 2px;
    }
    .el-dialog--small{
       width: 600px !important;
    }
    .pagination{
        text-align: left;
        margin-top: 10px;
    }

</style>


